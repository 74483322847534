<template>
    <div class="uni-tooltip">
        <slot></slot>
        <div v-if="content || $slots.content" class="uni-tooltip-popup">
            <slot name="content">
                {{ content }}
            </slot>
        </div>
    </div>
</template>


<script>
export default {
    name: "uni-tooltip",
    data() {
        return {};
    },
    methods: {},
    computed: {
        initPlacement() {
            let style = {};
            switch (this.placement) {
                case 'left':
                    style = {
                        top: '50%',
                        transform: 'translateY(-50%)',
                        right: '100%',
                        "margin-right": '10rpx',
                    }
                    break;
                case 'right':
                    style = {
                        top: '50%',
                        transform: 'translateY(-50%)',
                        left: '100%',
                        "margin-left": '10rpx',
                    }
                    break;
                case 'top':
                    style = {
                        bottom: '100%',
                        transform: 'translateX(-50%)',
                        left: '50%',
                        "margin-bottom": '10rpx',
                    }
                    break;
                case 'bottom':
                    style = {
                        top: '100%',
                        transform: 'translateX(-50%)',
                        left: '50%',
                        "margin-top": '10rpx',
                    }
                    break;
            }
            return style;
        }
    },
    props: {
        content: {
            type: String,
            default: ''
        },

        placement: {
            type: String,
            default: 'top'
        },
    }
}
</script>

<style>
.uni-tooltip {
    position: relative;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
}

.uni-tooltip-popup {
    width: 40vw;
    display: none;
    position: absolute;
    background-color: #333;
    border-radius: 8px;
    color: #fff;
    font-size: 19px;
    text-align: left;
    z-index: 100;
    line-height: 24px;
    padding: 12px;
    overflow: auto;
    bottom: 100%;
    transform: translateX(-50%);
    left: 50%;
    margin-bottom: 5px;
}


.uni-tooltip:hover .uni-tooltip-popup {
    display: block;
}
</style>