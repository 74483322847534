<template>
  <div class="container">
    <div style="height: 300px">
      <gd_map @initMap="initMap" />
    </div>
    <div class="header">
      <van-cell-group>
        <van-cell title="订单号">{{ order.orderNo }}</van-cell>
        <van-cell title="出发地">{{ order.startAddress }}</van-cell>
        <van-cell title="目的地">{{ order.endAddress }}</van-cell>
        <van-cell title="乘车人姓名">{{ order.passengerName }}</van-cell>
        <van-cell title="乘车人手机号">{{ order.passengerPhone }}</van-cell>
        <van-cell title="乘车人身份证号">{{ order.passengerCardNo }}</van-cell>
        <van-cell title="出发时间">{{ order.startTime }}</van-cell>
        <van-cell title="订单状态">{{ order.statusName }}</van-cell>
        <van-cell title="剩余时间" v-if="order.orderStatus !== 5 && order.doctorStatus == 5">已超时</van-cell>
        <van-cell title="审核不通过原因" v-if="order.orderStatus !== 5 && order.doctorStatus == 3">
          {{ order.doctorRemark }}
        </van-cell>
        <van-cell title="剩余时间" v-if="order.orderStatus !== 5 && order.endTime && order.doctorStatus == 3">{{
          order.countdown }}</van-cell>
        <van-cell class="van-cell-large"
          v-if="order.orderStatus !== 5 && order.endTime && (order.doctorStatus == 0 || order.doctorStatus == 1 || order.doctorStatus == 3)">
          <template #title>
            <tooltip>
              <template v-slot:content>
                <div class="">
                  90周岁以上的老人每月可享受2次就医订单免费。<br>注：杭州市范围内里程25公里，起点或终点为余杭区医院选择就医订单（提供就医凭证直接免单，事后48小时内提供先付后退）
                </div>
              </template>
              <span>就医凭证</span>
              <van-icon size="20" name="question-o" />
            </tooltip>
          </template>
          <van-uploader @delete="deleteImg" :after-read="afterRead" v-model="imgList" multiple :max-count="9" />
        </van-cell>

      </van-cell-group>
      <!-- <van-submit-bar v-if="order.orderStatus === 3" label="总金额 :" tip="仅支持支付宝支付,请确定手机上已安装支付宝!"
                      :price="order.payPrice * 100"
                      :loading="loading"
                      button-text="去支付"
                      @submit="onPay"/> -->
      <!--  -->
      <van-submit-bar
        v-if="order.orderStatus !== 5 && order.endTime && (order.doctorStatus == 0 || order.doctorStatus == 1 || order.doctorStatus == 3)"
        label="总金额 :" :price="order.payPrice * 100" :loading="loading" button-text="补充就医凭证" @submit="upload" />
    </div>


  </div>
</template>

<script>
import * as orderApi from "@/api/order";
import gd_map from "@/components/map/index";
import { Toast } from "vant";
import tooltip from "../components/tooltip/tooltip.vue";
export default {
  name: 'Home',
  components: { gd_map, tooltip },
  data() {
    return {
      timer: null,
      imgList: [],
      uploadForm: {
        orderId: null,
        voucherImages: []
      },
      order: {
        id: 1,
        orderNo: '',
        startAddress: '',
        endAddress: '',
        passengerName: '',
        passengerPhone: '',
        passengerCardNo: '',
        startTime: '',
        statusName: '',
        orderStatus: 0,
        countdown: ''
      },
      driving: null,
      loading: true
    }
  },
  created() {
    this.getOrderInfo();
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    initMap(driving) {
      this.driving = driving;
    },
    afterRead(file) {
      // console.log(file);
      if (Array.isArray(file)) {
        file.forEach(item => {
          let toast = Toast.loading({
            message: '上传中...',
            forbidClick: true,
            loadingType: 'circular',
            duration: 0
          });
          orderApi.uploadImg(item).then(res => {
            this.uploadForm.voucherImages.push(res.data)
          }).finally(() => {
            toast.clear();
          })
        })

      } else {
        let toast = Toast.loading({
          message: '上传中...',
          forbidClick: true,
          loadingType: 'circular',
          duration: 0
        });
        orderApi.uploadImg(file).then(res => {
          this.uploadForm.voucherImages.push(res.data.url)
        }).finally(() => {
          toast.clear();
        })
      }
    },
    deleteImg(file, detail) {
      this.uploadForm.voucherImages.splice(detail.index, 1);
    },
    upload() {
      if (this.uploadForm.voucherImages.length === 0) {
        this.close("请上传就医凭证");
        return;
      }
      orderApi.uploadVoucher(this.uploadForm).then(res => {
        console.log(res);
        this.getOrderInfo();
        Toast.success({
          message: '上传成功',
          duration: 3000,
          onClose: () => {
            window.close();
          }
        });
      }).catch(err => {
        console.log(err);
        this.close("上传失败");
      })
    },
    startCountdown() {
      this.timer = setInterval(() => {
        const currentTime = new Date().getTime();
        const endTime = new Date(this.order.endTime).getTime();
        const diff = endTime - currentTime
        // 计算倒计时的小时、分钟和秒数
        const hours = this.fit(Math.floor(diff / 3600000))
        const minutes = this.fit(Math.floor((diff % 3600000) / 60000))
        const seconds = this.fit(Math.floor((diff % 60000) / 1000))
        // 更新倒计时的值
        let time = hours + ": " + minutes + ": " + seconds
        this.$set(this.order, 'countdown', time)
      }, 1000);
    },
    fit(num) {
      return num < 10 ? "0" + num : num
    },
    getOrderInfo() {
      let shortNo = this.$route.params.shortNo;
      console.log('参数信息:', shortNo);
      let toast = Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'circular',
        duration: 0
      });
      if (shortNo) {
        let that = this;
        orderApi.orderInfo(shortNo).then(res => {
          if (res.data.doctorExpireTime) {
            res.data.doctorExpireTime = res.data.doctorExpireTime.replaceAll('-', '/') + ':00'
            const endTime = new Date(res.data.doctorExpireTime)
            res.data.endTime = endTime < new Date() ? '' : endTime
          }
          that.startCountdown()
          that.order = res.data;
          that.uploadForm.orderId = res.data.id;
          that.uploadForm.voucherImages = JSON.parse(JSON.stringify(res.data.voucherImages))
          res.data.voucherImages.map(item => {
            this.imgList.push({
              path: item,
              url: item
            })
          })
          toast.clear();
          that.loading = false;
          //为防止地图可能未加载好的情况,地图延迟1秒规划路线
          setTimeout(() => {
            // 根据起终点经纬度规划驾车导航路线
            that.driving.search([{ keyword: res.data.startAddress, city: '杭州' }, {
              keyword: res.data.endAddress,
              city: '杭州'
            }], function (status) {
              if (status === 'complete') {
                console.log('绘制驾车路线完成')
              } else {
                console.log('获取驾车数据失败：' + status)
              }
            });
          }, 1000);
        })
      } else {
        toast.clear();
        that.loading = false;
        this.close("订单信息错误");
      }
    },
    close(message) {
      Toast.fail({
        message: message,
        duration: 3000,
        onClose: () => {
          window.close();
        }
      });
    },
    onPay() {
      let data = {
        clientType: 0,
        orderId: this.order.id,
        returnUrl: window.location.origin + "/payResult",
      };
      orderApi.orderPay(data).then(res => {
        console.log("h5支付");
        let divForm = document.getElementsByTagName('divform');
        if (divForm.length) {
          document.body.removeChild(divForm[0]);
        }
        const div = document.createElement('divform');
        div.innerHTML = res.data; // res.data就是支付宝返回给你的form
        document.body.appendChild(div);
        document.forms[0].submit();
        div.remove();
      })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .van-uploader__preview-image {
  width: 38px;
  height: 38px;
}

::v-deep .van-uploader__upload {
  width: 38px;
  height: 38px;
}

.van-cell-large {
  overflow: visible !important;

  .uni-tooltip {
    display: flex;
    align-items: center;
  }
}

.container {
  background-color: #F7F7F7;

  .header {
    // padding: 0 25px;
    background-color: #fff;
  }

  .content {
    margin-top: 20px;
    padding: 0 50px;
    background-color: #fff;
  }
}
</style>
